var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"d-print-none",attrs:{"color":"navbar","clipped-left":false,"fixed":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('hide', !_vm.show)}}}),_c('v-spacer'),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',[_vm._v("info")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":""}},on),[_c('flag',{attrs:{"iso":_vm.currentFlag,"squared":false}}),_c('v-icon',{attrs:{"right":"","color":"primary"}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.languagess),function(entry,index){return _c('v-list-item',{key:(index + "010"),on:{"click":function($event){return _vm.changeLocale(entry)}}},[_c('v-list-item-title',[_c('flag',{attrs:{"iso":entry.flag,"squared":false}}),_vm._v(" "+_vm._s(entry.title)+" ")],1)],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"}},on),[_c('div',[_c('v-icon',[_vm._v(" mdi-bell ")])],1)])]}}])}),(_vm.currentUser)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","offset-y":"","width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"}},on),[_c('v-avatar',{attrs:{"size":"22","item":""}},[(_vm.currentUser.candidate.photo)?_c('img',{attrs:{"src":(_vm.apiUrl + "/images/candidate/" + (_vm.currentUser.candidate.photo))}}):_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-account-circle")])],1)],1)]}}],null,false,1014949315)},[_c('v-card',{staticClass:"mx-auto"},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.currentUser.candidate.photo
                    ? (_vm.apiUrl + "/images/candidate/" + (_vm.currentUser.candidate.photo))
                    : "/avatar.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(((_vm.currentUser.candidate.name.split(" ")[0]) + " " + (_vm.currentUser.candidate.lastName)))+" ")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.currentUser.candidate.email)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',{attrs:{"router":"","to":"/candidate/candidate/profile"}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-account-circle")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("form_candidate_profile")))])],1),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout($event)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }