<template>
  <v-app-bar
    color="navbar"
    :clipped-left="false"
    fixed
    app
    class="d-print-none"
  >
    <v-app-bar-nav-icon @click.stop="$emit('hide', !show)"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->

    <v-spacer></v-spacer>
    <div class="px-1">
      <v-btn icon color="primary" @click="openDialog()">
        <v-icon>info</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color v-on="on">
            <flag :iso="currentFlag" v-bind:squared="false" />
            <v-icon right color="primary">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(entry, index) in languagess"
            :key="`${index}010`"
            @click="changeLocale(entry)"
          >
            <v-list-item-title>
              <flag :iso="entry.flag" v-bind:squared="false" />
              &nbsp;{{ entry.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <div>
              <v-icon> mdi-bell </v-icon>
            </div>
          </v-btn>
        </template>
        <!-- <v-card class="mx-auto">
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-title>Candidaturas</v-list-item-title>
            </v-list-item>
            <v-divider class="mx-3" style="margin-top:-2.5%"></v-divider>
            <template v-for="(value, index) in privateVacancy">
              <div
                :key="`${index}`"
           
              >
              <template >
                <div >
                <v-list-item >
                
                  <v-list-item-avatar>
                    <-- <img
                      :src="
                        value.logo
                          ? `${apiUrl}/images/entity/${value.logo}`
                          : `/logo_default.png`
                      "
                      alt
                    /> --
                     <v-icon class="primary--text">mdi-check-circle-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ value[0].entityName }} </v-list-item-title>
                    <div >
                      <v-list-item-subtitle >
                        Ola! foi selecionado para candidatar-se a vaga de <span class="font-weight-bold black--text">contabilista</span>
                        <hr class="ma-1">
                        Ola! foi selecionado para candidatar-se a vaga de <span class="font-weight-bold black--text">design</span>
                        <-- {{value[0].description }} --

                        </v-list-item-subtitle
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
                </div>
              </template>
              </div>
            </template>
          </v-list>
        </v-card> -->
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
        width="400px"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <v-avatar size="22" item>
              <img
                v-if="currentUser.candidate.photo"
                :src="`${apiUrl}/images/candidate/${currentUser.candidate.photo}`"
              />
              <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="mx-auto">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="
                    currentUser.candidate.photo
                      ? `${apiUrl}/images/candidate/${currentUser.candidate.photo}`
                      : `/avatar.png`
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{`${currentUser.candidate.name.split(" ")[0]} ${currentUser.candidate.lastName}`}}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ currentUser.candidate.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item router to="/candidate/candidate/profile">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{
                $t("form_candidate_profile")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { SEND_STATUS, USER_QUERY } from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import { NOTIFY_CANDIDATE } from "./../graphql/notify/subscription";
import { groupBy } from "@/utils";
import {
  GET_CANDIDATE_NOTIFY,
  GET_ENTITY_LIST,
} from "./../graphql/notify/Query";
import { ACTIVE_CANDIDATE } from '../graphql/Mutation';
export default {
  name: "AppToolbar",
  props: {
    show: Boolean,
  },
  model: {
    prop: "show",
    event: "hide",
  },
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "pt",
    showLogoutDialog: false,
    privateVacancy: [],
    entity: [],
  }),
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    entities: {
      query: GET_ENTITY_LIST,
      fetchPolicy: "no-cache",
    },
    getCandidateNotify: {
      query: GET_CANDIDATE_NOTIFY,
      variables() {
        return { candidateId: this.currentUser.candidate.id };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.getCandidateNotify) {
          const array = data.getCandidateNotify.content.filter(
            (e) => e.type === "privateVacancy"
          );

          this.privateVacancy = groupBy(
            array,
            "entityName",
            (record, dateKey) => {
              return [record[dateKey]];
            }
          );

          console.log("this.privateVacancy", this.privateVacancy);
        }
      },
    },
    $subscribe: {
      candidateNotify: {
        query: NOTIFY_CANDIDATE,
        result({ data }) {
          console.log(data.candidateNotify);
        },
      },
    },
  },

  watch: {
    user: function (val) {
      this.setUserState(val);
    },
  },
  created() {
    window.addEventListener("beforeunload", this.leaving);
    if (localStorage.getItem("lang") != null) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    } else {
      this.$i18n.locale = "pt";
      this.currentFlag = "pt";
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    candidate() {
      return this.currentUser ? this.currentUser.candidate : {};
    },
    languagess: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    userId() {
      return this.$root.$data.userId;
    },
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);

      console.log(locale, localStorage.getItem("lang"));
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },
    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },

    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
          locale: this.$i18n.locale,
          candidate: this.currentUser.candidate.id,
        },
      });
    },
    async backOnline() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
          locale: this.$i18n.locale,
          candidate: this.currentUser.candidate.id,
        },
      });
    },
    async activeAccount() {
      await this.$apollo.mutate({
        mutation: ACTIVE_CANDIDATE,
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
      //this.activeAccount();
      // this.$emit("hide", false);
    }, 1000);
  },
};
</script>
